<template>
    <div style="z-index: -12112;" >
    <div class="mainContainers" >
        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
            <div class="doctorNameAndLogo">
                <div class="doctorProfile">
                    <img :src="item.doctorInfo.doctorLogo" style="border-radius: 50%;"/>
                </div>
                <div class="doctorName">
                    <div style="font-weight: 500; font-size: 14px;">{{ item.doctorInfo.doctorNameEN }}</div>
                    <div style="color: #1467BF; font-weight: 400; font-size: 14px; text-align: left;" class="specialtyBox" v-if="specialtyList">{{ getSpecialtyName(item) }}</div>
                </div>
            </div>
            <div>
                <v-btn 
                    outlined
                    color="#e8e8eb"
                    @click="openDoctorBox"
                    :disabled="viewDoctorLoader"
                    :loading="viewDoctorLoader"
                >
                    <div style="color: #1467BF; font-weight: 400; font-size: 14px;">View</div>
                </v-btn>
            </div>
        </div>
        <!-- location and degree -->
        <div class="" style="display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap: 16px;">
            <div style="display: flex; gap: 10px; width: 100%; align-items: flex-start;">
                <img src="https://s3iconimages.mymedicine.com.mm/degree.svg" alt="" style="width: 28px; height: 28px;">
                <div style="display: flex; flex-wrap: wrap; justify-content: flex-start; text-align: left;">
                    <div v-for="(degree, index) in limitedAcademicTitles" style="padding-right: 4px; font-size: 14px; font-weight: 400; color: #4f4f4f;" v-if="academicsList[degree]">{{ academicsList[degree].name }}, </div>
                    <div v-if="showViewMore" style="padding-right: 4px; font-size: 14px; color: #1467bf;">
      <a href="#" @click.prevent="openDoctorBox">View More</a>
    </div>
                </div>
            </div>
            <!-- <div style="display: flex; gap: 10px; width: 100%; align-items: center;">
                <img src="https://s3iconimages.mymedicine.com.mm/locationIcn.svg" alt="" style="width: 28px; height: 28px;">
                <div style="padding-right: 4px; font-size: 14px; font-weight: 400; color: #4f4f4f;"> {{ item.doctorInfo.location }}</div>
            </div> -->

        </div>
        <!-- location and degree ends -->
        <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; width: 100%;">
                <div style="color: #828282; font-size: 14px;">Select Date</div>
                <div style="display: flex; justify-content: center; align-items: center; gap: 10px;" v-if="showSlot" @click="toggleSlot">
                    <div style="font-size: 14px; color: #1467bf;">Hide Slots</div>
                    <img src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg" alt="" style="width: 10px; height: 10px; rotate: -90deg;">
                </div>
                <div style="display: flex; justify-content: center; align-items: center; gap: 10px;" v-if="!showSlot" @click="toggleSlot">
                    <div style="font-size: 14px; color: #1467bf;">View Slots</div>
                    <img src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg" alt="" style="width: 10px; height: 10px; rotate: 90deg;">
                </div>
            </div>
        <v-expand-transition>
            <v-card v-show="showSlot" elevation="0" tile width="100%">
        <!-- select date start -->
        <div style="display: flex; flex-direction: column; width: 100%; align-items: flex-start; gap: 8px;">

            <div v-if="slotLoader" style="width: 95%; display: flex; gap: 8px; justify-content: space-between;">
                <v-skeleton-loader  
                    type="chip"
                ></v-skeleton-loader>
                <v-skeleton-loader  
                    type="chip"
                ></v-skeleton-loader><v-skeleton-loader  
                    type="chip"
                ></v-skeleton-loader>
            </div>
            <div style="width: 100%;" v-if="showSlot">
            <div v-if="noSlot" style="color: gray;">{{$t("Customer.OpdAppointments.No_Slots_available")}}</div>

                <div v-else 
                class="dateContainer"
                    >
                        <div :class="getClassForDateBox(slotItem.slotID, slotItem)" v-for="(slotItem) in slotInfo" :key="slotItem.slotID" @click="showSelectSlotFn(slotItem.slotID, slotItem)">
                            <div v-if="slotItem.slotID == 'CALENDAR'" style="display: flex; align-items: center; justify-content: center;">
                                <img :src="selectedID == 'CALENDAR' ? 'https://s3iconimages.mymedicine.com.mm/calendarBlue.svg': 'https://s3iconimages.mymedicine.com.mm/calendarGrey.svg'" alt="" style="width: 30px; height: 30px;">
                            </div>
                            <div>
                                <div  class="weekDayClass" style="color: #828282; font-size: 14px;">{{ slotItem.dayValue }}</div>
                                <div class="weekDayClass" style="font-weight: 600; font-size: 14px;">{{ slotItem.dateValue }}</div>
                            </div>    
                        </div>
                </div>
            </div>
        </div>
        <!-- select date end -->

        <!-- select slot start -->
        <div v-if="showSlot && showSelectSlot" style="display: flex; flex-direction: column; width: 100%; align-items: flex-start; gap: 8px;">
            <div style="color: #828282; font-size: 14px;">Select Slot</div>
            <div style="width: 100%; display: flex; justify-content: center;">
                <div style="display: flex; flex-direction: row; width: 100%; flex-wrap: wrap; gap: 8px;" 
                    >
                        <div :class="getClassForSlotBox(timeID.id)" v-for="(timeID) in selectedDateItem.slotTimes" :key="timeID.id" @click="showAddPatientFn(timeID.id, timeID)" :disabled="!calculateDateTime(cutOffHours, selectedDateItem.slotID, timeID.startTime)">
                           <div class="weekDayClass">{{ timeID.timeInfo }}</div>
                        </div>
                    </div>
            </div>
        </div>


        <!-- select patient start -->
        <div v-if="showSlot && showAddPatient" style="display: flex; flex-direction: column; width: 100%; align-items: flex-start; gap: 8px;">
            <div style="color: #828282; font-size: 14px;">Who is the Patient?</div>
            <div style=" gap: 8px; width: 100%; display: flex;  align-items: flex-start;">
                <v-select
                @input="enableStateList"
                :items="dependent_members"
                outlined
                style="height: 40px;"
                dense
                item-value="_id"
                item-text="dependent_name"
                v-model="dependentSelected"
                >
                    <template v-slot:selection="data">
                        <span style="padding-left: 10px;">{{ data.item.dependent_name }}</span>
                    </template>
                </v-select>
                <div style="height: 100%;">
                    <v-btn depressed color="#e4f5ff" height="40" min-width="40" @click="addDependentModel = true">
                        <img src="https://s3iconimages.mymedicine.com.mm/pluseIcon.svg" alt="">
                    </v-btn>
                </div>
            </div>
        </div>
        <div v-if="showSlot && showAddPatient && showStateList" style="display: flex; flex-direction: column; width: 100%; align-items: flex-start; gap: 8px;">
            <div style="color: #828282; font-size: 14px;">State/Township</div>
            <div style=" gap: 8px; width: 100%; display: flex;  align-items: flex-start;">
                <div class="inputBox">
                        <v-autocomplete
                            @input="stateSelectionChange"
                            :items="stateList"
                            :dense="true"
                            placeholder="Select state"
                            label=""
                            item-text="name"
                            item-value="id"
                            outlined
                            v-model="selectedState"
                        >
                        <template v-slot:selection="data">
                        <span style="padding-left: 10px;">{{ data.item.name }}</span>
                    </template>
                    </v-autocomplete>
                </div>
            </div>
        </div>
        </v-card>
        </v-expand-transition>

            <div>
            <v-bottom-sheet  :persistent="true" v-model="openCalender" max-width="425px" >
                <v-sheet
                class="text-center"
                height="450px"
                >
                <div class="viewDetailsBottomContainer">
                    <div>
                        <div class="viewHospitalDetailsBottomSheetHeading">
                            <div style="font-size: 14px; font-weight: 500; color: #4f4f4f;">Select Date</div>
                            <img src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg"  @click="() => {openCalender = false;}" style="cursor: pointer; width: 25px;"/>
                        </div>
                    </div>
                    <div class="viewDetailsBody">
                        <v-date-picker v-model="datePickerModel" :no-title="true" :full-width="true" :allowed-dates="isDateAllowed"></v-date-picker>
                    </div>
                    <div class="bottomBar">
                        <div>Clear</div>
                        <v-btn color="#48acef" depressed style="color: white; background-color: #48acef;" @click="getParticularDateData" :disabled="calendarLoader || datePickerModel == null" :loading="calendarLoader" >Submit</v-btn>
                    </div>
                    
                </div>

            </v-sheet>
            </v-bottom-sheet>
            </div>
            <div>
            <v-bottom-sheet  :persistent="true" v-model="reviewDialg" max-width="425px"  v-if="bookingAllowed">
                <v-sheet
                class="text-center"
                height="450px"
                >
                <div class="viewDetailsBottomContainer">
                    <div>
                        <div class="viewHospitalDetailsBottomSheetHeading">
                            <div style="font-size: 14px; font-weight: 500; color: #4f4f4f;">Review Details</div>
                            <img src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg"  @click="() => {reviewDialg = false;}" style="cursor: pointer; width: 25px;"/>
                        </div>
                    </div>
                    <div class="viewDetailsBody">
                        <doctor-info-component :bookingInfo="bookingInfo"/>
                    </div>
                    <div class="bottomBar">
                        <v-btn depressed color="#48acef" style="color: white;" width="100%" height="40px" @click="bookSlot" :disabled="bookingLoader" :loading="bookingLoader">
                            <img src="https://s3iconimages.mymedicine.com.mm/whiteCircleTick.svg" alt="" style="width: 25px; height: 25px; margin-right: 10px;">
                            {{$t("Customer.PackageAppointments.Confirm_Book")}}
                        </v-btn>
                    </div>
                    
                </div>

            </v-sheet>
            </v-bottom-sheet>
            </div>
        </div>
        <div class="confirmBookingComponent" v-if="bookingAllowed">
            <v-btn depressed color="#48acef" style="color: white;" width="100%" height="40px" @click="openReviewBox">
                <img src="https://s3iconimages.mymedicine.com.mm/whiteCircleTick.svg" alt="" style="width: 25px; height: 25px; margin-right: 10px;">
                {{$t("Customer.OpdAppointments.Review")}}
            </v-btn>
        </div>
        <div v-if="viewDoctorModel">
            <view-doctor-component @toggleViewDoctorDialog="toggleViewDoctorDialog" :doctorInfo="doctorData" :modelValue="viewDoctorModel"/>
        </div>
        <div v-if="addDependentModel">
            <add-dependent-component :modelValue="addDependentModel" @addDependentInList="addDependentInList" @toggleAddDependentDialog="toggleAddDependentDialog"/>
        </div>
    </div>

</template>

<script>
import { axios_auth_instance_customer, axios_auth_instance_hba_customer } from '../../../../utils/axios_utils';
import doctorInfoComponent from '../bookingSuccessPage/doctorInfoComponent.vue';
import AddDependentComponent from '../commonAcrossPages/addDependentComponent.vue';
import ViewDoctorComponent from '../commonAcrossPages/viewDoctorComponent.vue';

export default {
  components: { doctorInfoComponent, ViewDoctorComponent, AddDependentComponent },
    name: 'ViewParticularDoctorComp',
    props: ["dependent_members", "item", "isSelectedDoctorBooking"], 
    data: function() {
        return {
            bookingInfo: {},
            bookingAllowed: false,
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            showStateList: false,
            bookingLoader: false,
            doctorData: null,
            showSelectSlot: false,
            selectedID: -1,
            showAddPatient: false,
            selectedSlotId: -1,
            reviewDialg: false,
            items: ["My Self", "Ankur"],
            currentCustomer: null,
            selectedDateItem: null,
            openCalender: false,
            datePickerModel: null,
            dependentSelected: null,
            showSlot: false,
            slotInfo: [],
            slotLoader: false,
            overlay: false,
            calendarLoader: false,
            allowedDates: [],
            timeInfo: '',
            viewDoctorModel: false,
            cutOffHours: 0,
            calendarDateNumber: null,
            addDependentModel: false,
            selectedState: null,
            viewDoctorLoader: false,
            noSlot: false,
            // dependent_members: []
        };
    },
    async mounted() {
        this.allowedDates = this.getXDaysFromToday(90);
        // alert(this.isSelectedDoctorBooking);
        this.updateShowSlot();

    },
    watch: {
    isSelectedDoctorBooking() {
      this.updateShowSlot();
    }
  },
    computed: {
        stateList () {
            return this.$store.state.locationState.showLocationList;
        },
        hospitalData () {
            return this.$store.state.particularHospitalState.hospitalInfo;        
        },
        specialtyList () {
            return this.$store.state.OPDConfigCommonState.opdSpec;        
        },
        academicsList () {
            return this.$store.state.OPDConfigCommonState.academics;        
        },
        limitedAcademicTitles() {
      return this.item.doctorInfo.academicTitle.slice(0, 2);
    },
    showViewMore() {
      return this.item.doctorInfo.academicTitle.length > 2;
    },
    },
    methods: {
       
        updateShowSlot() {
      if (this.isSelectedDoctorBooking !== this.item.doctorInfo.id) {
        this.showSlot = false;
            this.selectedID = null;
            this.selectedDateItem = null;
            this.selectedSlotId = null;
            this.showAddPatient = false;
            this.showSelectSlot = false;
            this.timeInfo = null;
            this.dependentSelected = null;
            this.bookingAllowed = false;
            this.selectedState = null;
      } else {
        this.showSlot = true;
      }
    },
        resetAllVariables() {
        
        },
        getSpecialtyName(item) {
            let findItem = this.specialtyList.find((x) => (x.id == item.doctorInfo.doctorSpecialty));
            console.log(findItem, item, this.specialtyList);
            if (findItem) {
                return findItem.name;
            } else {
                return ''
            }
        },
        async openDoctorBox() {
            this.viewDoctorLoader = true;
            try {
                if (this.doctorData == null) {
                    let apiResponse = await axios_auth_instance_hba_customer(`/opd/doctorInfo/${this.item.doctorInfo.id}`);
                    this.doctorData = apiResponse.data.doctorDetails;
                }
                this.viewDoctorModel = true;
                this.viewDoctorLoader = false;
            } catch (error) {
                console.log(error);
            } 
        },
        addDependentInList(item) {
            this.dependent_members.push(item);
            this.dependentSelected = item._id;
            this.enableStateList();
            this.addDependentModel = false;
        },
        toggleAddDependentDialog(value) {
            if(value) {
                this.addDependentModel = true;
            } else {
                this.addDependentModel = false;
            }
        },
        toggleViewDoctorDialog(value) {
            if(value) {
                this.viewDoctorModel = true;
            } else {
                this.viewDoctorModel = false;
            }
        },
        formatDate(dateString) {
        // Extract year, month, and day from the given string
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6) - 1; // Months are zero-based in JavaScript
        const day = dateString.substring(6, 8);

        // Create a new Date object
        const date = new Date(year, month, day);

        // Get the day and month name
        const dayName = date.toLocaleString('en-US', { weekday: 'long' });
        const monthName = date.toLocaleString('en-US', { month: 'long' });

        // Format the date
        const formattedDate = `${day} ${monthName}`;

        return formattedDate;
        },

    openReviewBox() {
        console.dir(this.selectedDateItem);
        console.log(this.selectedID);
        this.bookingInfo = {
            'doctorInfo': {
                doctorName: this.item.doctorInfo.doctorNameEN,
                doctorLogoURL: this.item.doctorInfo.doctorLogo,
                doctorSpecialty: this.item.doctorInfo.doctorSpecialty,
                hospitalLogo: this.hospitalData.hospitalLogo,
                hospitalName: this.hospitalData.name,
                hospitalAddress: this.hospitalData.address,
                slotTime: this.timeInfo,
                slotDate: this.selectedID == 'CALENDAR' ? this.formatDate(this.calendarDateNumber) : this.formatDate(this.selectedID)            
            }
        }
        this.reviewDialg = true;
        console.dir(this.bookingInfo);
    },
    isDateAllowed(date) {
      console.log(date);
      return this.allowedDates.includes(date);
    },
    getXDaysFromToday(x) {
        const dates = [];
        const today = new Date();
        for (let i = 0; i < x; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            const yyyy = date.getFullYear();
            const mm = String(date.getMonth() + 1).padStart(2, '0');
            const dd = String(date.getDate()).padStart(2, '0');
            dates.push(`${yyyy}-${mm}-${dd}`);
        }
        return dates;
    },
  calculateDateTime(hours, dateNumber, startTime) {
    console.log(hours, dateNumber, startTime);
    const today = new Date();
    hours = Number(hours);
    today.setHours(today.getHours() + hours);
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const dd = String(today.getDate()).padStart(2, '0');
    const HH = String(today.getHours()).padStart(2, '0');
    const mmFormat = String(today.getMinutes()).padStart(2, '0');
    const dateObject = {
        dateValue: Number(`${yyyy}${mm}${dd}`),
        time: `${HH}:${mmFormat}`
    };
    // Check if dateNumber and startTime are greater than dateObject values
    const inputDateValue = Number(dateNumber);
    const inputTimeValue = Number(startTime.replace(':', ''));
    let allowed = inputDateValue > dateObject.dateValue ||
            (inputDateValue === dateObject.dateValue && inputTimeValue > Number(dateObject.time.replace(':', '')));
            console.log(allowed);
    return allowed;
    },

        async bookSlot() {
            let maxRetries = 3; // Number of retries
            let attempt = 0;
            let success = false;
            this.bookingLoader = true;
            while (attempt < maxRetries && !success) {
                try {
                    let apiResponse = await axios_auth_instance_hba_customer.post('/opd/book', {
                        hospital_ID: this.item.doctorInfo.hospitalRegNo,
                        slot_ID: `${this.item.doctorInfo.id}#${this.selectedSlotId}`,
                        booked_for: this.dependentSelected,
                        state_ID: String(this.selectedState),
                        speciality: this.getSpecialtyName(this.item)
                    });
                    let bookingID = apiResponse.data.bookingID;
                    success = true;
                    this.$router.push({
                        name: 'hospitalBookingSuccess',
                        params: {
                            bookingID: bookingID,
                        }
                    });
                } catch (error) {
                    attempt++; // Increase attempt count on error
                    if (attempt >= maxRetries) {
                        console.log('Max retries reached:', error);
                    } else {
                        console.log(`Retry attempt ${attempt} failed, retrying...`, error);
                    }
                }
            }
            this.bookingLoader = false;
        },
        async toggleSlot() {
            this.$emit('toggleSlot', {
                id: this.item.doctorInfo.id
            });
            this.selectedID = null;
            this.selectedDateItem = null;
            this.selectedSlotId = null;
            this.showAddPatient = false;
            this.showSelectSlot = false;
            if (this.showSlot === false) {
                try {
                    if (this.slotInfo.length != 0) {
                        this.showSlot = true;
                        return;
                    }
                    this.noSlot = false;
                    this.slotLoader = true;
                    let apiResponse = await axios_auth_instance_hba_customer.get(`/opd/slotInfo/${this.item.doctorInfo.hospitalRegNo}/${this.item.doctorInfo.id}`);
                    this.slotInfo = apiResponse.data.slotList;
                    this.cutOffHours = apiResponse.data.cutOffHour;
                
                    // this.slotInfo.push({
                    //         slotID: 'CALENDAR',   
                    //     }
                    // );
                    if (apiResponse.data.noSlot) {
                        this.noSlot = true;
                    }

                    this.showSlot = true;
                } catch (error) {
                    if (error.responseStatus == 401 || error.responseStatus == 403) {
                        localStorage.setItem('redirectionFlow', true);
                        localStorage.setItem('redirectionURL', this.$router.history.current.fullPath);
                        this.$router.push({
                            name: 'Login'
                        });
                    }
                } finally {
                    this.slotLoader = false;
                }
            } else {
                this.showSlot = false;
            }

        },
        enableStateList() {
            // this.bookingAllowed = true;
            this.showStateList = true;
            this.selectedState = null;
            this.bookingAllowed = false;
            let index = this.dependent_members.findIndex((item) => (item._id == this.dependentSelected))
            if(this.dependent_members[index].stateID) {
                this.selectedState = this.dependent_members[index].stateID;
                this.bookingAllowed = true;
            }
        },
        stateSelectionChange() {
            this.bookingAllowed = true;
        },
        async getParticularDateData() {
            try {
                this.calendarLoader = true;
                this.overlay = true;
                let splitedDate = this.datePickerModel.split('-');
                let dateInput = splitedDate[0] + splitedDate[1] + splitedDate[2];
                let apiResponse = await axios_auth_instance_hba_customer.get(`/opd/slotInfo/${this.item.doctorInfo.hospitalRegNo}/${this.item.doctorInfo.id}/${dateInput}`);
                if(apiResponse.data.slotList.length == 0) {
                    alert('No slots available');
                    return;
                }
                this.selectedDateItem = {
                    slotID: 'CALENDAR',
                    slotTimes: apiResponse.data.slotList
                }
                this.calendarDateNumber = dateInput;
                this.showSelectSlot = true;
                this.openCalender = false;
            } catch (error) {
                console.log(error);
            } finally {
                this.calendarLoader = false;
            }

        },  
        getClassForDateBox(index, slotItem) {
            // if(slotItem.slotID == 'CALENDAR' && index != this.selectedID) return "dateBox"

            if(slotItem.slotID != 'CALENDAR' && slotItem.slotTimes.length == 0) {
                return "dateBox disabledDateBox"
            }
            if(index == this.selectedID) {
                return "dateBox selectedDateBox"
            } else {
                return "dateBox"
            }
        },
        getClassForSlotBox(index) {
            if(index == this.selectedSlotId) {
                return "slotBox selectedDateBox"
            } else {
                return "slotBox"
            }
        },
        showSelectSlotFn(id, slotItem) {
            this.selectedID = null;
            this.selectedDateItem = null;
            this.selectedSlotId = null;
            this.showAddPatient = false;
            this.showSelectSlot = false;
            this.timeInfo = null;
            this.dependentSelected = null;
            this.bookingAllowed = false;
            this.selectedState = null;
            // if(id == 'CALENDAR') return;
            if(id == 'CALENDAR') {
                this.selectedID = id;
                this.selectedDateItem = slotItem;
                this.openCalender = true;
                return;
            }
            if(slotItem.slotTimes.length == 0) return;
            this.selectedID = id;
            this.selectedDateItem = slotItem;
            this.showSelectSlot = true;
        },
        showAddPatientFn(id, item) {
            if(this.calculateDateTime(this.cutOffHours, this.selectedDateItem.slotID == 'CALENDAR' ? this.calendarDateNumber : this.selectedDateItem.slotID, item.startTime) == false) {
                alert('Booking not allowed');
                return;
            }
            // if any selected before
            this.dependentSelected = null;
            this.selectedSlotId = null;
            this.selectedState = null;
            this.timeInfo = null;
            this.showAddPatient = true;
            this.bookingAllowed = false;
            this.selectedSlotId = id;
            this.timeInfo = item.timeInfo;

        },
        goToHospitalPage() {
            this.$router.push({
            name: "ViewHospital",
            });
        },
    },
};

</script>
<style  scoped>
.confirmBookingComponent {
    position: absolute;
    bottom: 0;
    background: white;
    padding: 14px 16px 12px 16px;
    width: 100%;
    max-width: 425px;
    z-index: 1;
    /* margin: 0 !important; */
}
.mainContainers {
    margin: 0 16px;
    padding: 16px;
    padding-bottom: 0;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    cursor: pointer;
    z-index: -2122;
}
.headerHeading {
    color: white;
    font-weight: 600;
}
.doctorName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}
.doctorProfile {
    border-radius: 50%;
    max-height: 56px;
    max-width: 56px;
    height: 56px;
    width: 56px;
    border: 1px solid #e0e0e0;
}
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.doctorNameAndLogo {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-grow: 1;
}
.specialtyBox {
    display: flex;
    align-items: center;
    background: #E4F5FF;
    padding: 3px 8px 3px 8px;
}
.dateBox {
    display: flex;
    /* padding: 4px 12px 4px 12px; */
    border: 1px solid #e0e0e0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    /* width: 50px; */
    /* height: 50px; */
}
.dateContainer {
    display: grid;
    grid-template-columns: repeat(7, 0.5fr); /* 6 columns with equal width */
    gap: 6px; /* Gap between boxes */
    max-width: 475px;
  }
.selectedDateBox {
    background: #E4F5FF;
    border: 1px solid #48ACEF !important;
}
.disabledDateBox {
    background: #FFF;
    border: 1px solid #f2f2f2 !important;
}
.selectedDateBox .weekDayClass {
    color: #48ACEF !important;
}
.disabledDateBox .weekDayClass {
    color: #e0e0e0 !important;
    font-weight: 800 !important;
}
.slotBox {
    display: flex;
    align-items: center;
    padding: 10px 12px 10px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    color: #828282;
    font-weight: 400;
    width: 100%;
}
.v-input >>> .v-input__slot {
    padding: 0 !important;
}
.v-input__slot >>> .v-text-field {
    display: none;
}
.v-sheet {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.viewHospitalDetailsBottomSheetHeading {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8eb;
}
.viewDetailsBottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}
.viewDetailsBody {
    padding: 20px 16px 20px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}
.bottomBar {
    border-top: 1px solid #e8e8eb;
    display: flex; justify-content: space-between; align-items: center; width: 100%;
    padding: 12px 16px 12px 16px;
    box-shadow: 0px -4px 8px 0px #0000000A;
    box-shadow: 0px 4px 4px 0px #00000040;
    position: absolute;
    bottom: 0;
    max-width: 425px;
}
.inputBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
}
::v-deep .v-input{
    width: 100%;
}
::v-deep .v-text-field__details{
    display: none;
}
::v-deep .v-date-picker-table .v-btn.v-btn--active {
    background-color: #1467BF !important;
    /* color: #fff !important; */
}

</style>